import { Injectable, Inject } from '@angular/core'
import { registerLocaleData } from '@angular/common'
import { DOCUMENT } from '@angular/common';
import localeSwedish from '@angular/common/locales/sv'
import localeSpanish from '@angular/common/locales/es'
import localeFrench from '@angular/common/locales/fr'
import localeGerman from '@angular/common/locales/de'
import localeItalian from '@angular/common/locales/it'
import localeDanish from '@angular/common/locales/da'
//import 'moment/locale/sv'

import {Language } from '../interfaces'
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class LangService {

  languages: Language[] = []
  activeLanguageCode = ''
  defaultLanguageCode = ''
  activeLocale = 'en_US'
  translations = []
  numberFormat = '1.0-0'
  ph = 0 // PipeHelper
  trace = false

  debug = false

  constructor(@Inject(DOCUMENT) private document: Document) { }

  async getLanguages() {}

  setLang(languageCode) {

    if (this.debug) console.log('Setlang', languageCode)

    this.activeLanguageCode = languageCode
    this.ph++
    localStorage.setItem('languageCode', languageCode)

    this.document.documentElement.lang = languageCode; 

    console.log('lang is', languageCode)
    if (languageCode == 'se') {
      moment.locale('se')  
    } else {
      moment.locale(languageCode)
    }
    
    //console.log('lang is', this.activeLanguageCode)
    
  }

//  setNextLang() {
//    
//    let prodLangs = this.languages.filter(l => l.inProducts)
//
//    let i = prodLangs.findIndex(l => l.languageCode == this.activeLanguageCode)
//    i += 1
//    if (i >= prodLangs.length) i = 0
//
//    this.setLang(prodLangs[i].languageCode)
//    this.setLocale(prodLangs[i].locale)
//    
//  
//  }

  public l(nr: number): string {

    let s = this.translations.find(t => t.languageCode == this.activeLanguageCode && t.nr == nr) || this.translations.find(t => t.languageCode == this.defaultLanguageCode && t.nr == nr)

    if (!s) s = this.translations.find(t => t.languageCode == 'en' && t.nr == nr)
    
    if (!s) {
      s = { text: '' }      
    }

    return `${this.trace ? `${nr} ` : ''}${s.text}`

  }  

  setLocale(languageLocale) {

    if (this.debug) console.log('setLocale', languageLocale)

    this.activeLocale = languageLocale

    switch(languageLocale) {

      case 'sv_SE':
        registerLocaleData(localeSwedish)
        break

      case 'es_ES':
        registerLocaleData(localeSpanish)
        break

      case 'fr_FR':
        registerLocaleData(localeFrench)
        break

      case 'it_IT':
        registerLocaleData(localeItalian)
        break

      case 'de_DE':
        registerLocaleData(localeGerman)
        break
  
      case 'da_DK':
        registerLocaleData(localeDanish)
        break
    
          } 

  }  
  
}
