import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'active'
})
export class ActivePipe implements PipeTransform {

  transform(objs: any[]): any[] {
    return objs.filter(obj => obj.active)
  }

}
