import { Component, OnInit } from '@angular/core';
import { DbService, LangService } from '../../services'
import { Order, TimeSpan } from '../../interfaces'
import moment from 'moment';
import { RRule, RRuleSet, rrulestr } from 'rrule'

@Component({
  selector: 'app-step20',
  templateUrl: './step20.component.html',
  styleUrls: ['./step20.component.scss']
})
export class Step20Component implements OnInit {


  debug = false 

  selectedSlot = {} as any
  selectedTime = {} as any

  orders: Order[] = []

  constructor(public db: DbService, public lang: LangService) {

    this.createSlots()
    
    // If only 1 date is available, select it
    if (this.db.slots.filter(slot => slot.available).length == 1) {
      let slot = this.db.slots.find(slot => slot.available)
      if (slot) this.selectDate(slot)
    }
  }

  ngOnInit(): void {
  }

  createSlots() {

    this.db.slots = []

    let daysAhead = this.db.orderType.daysAhead || 0

    for (let n = 0; n <= daysAhead; n++) {

      let day = moment().add(n, 'day')
      let dow = day.format('dddd D/M')

      if (this.lang.activeLanguageCode == 'se') dow = dow.replace('Monday', 'Måndag').replace('Tuesday', 'Tisdag').replace('Wednesday', 'Onsdag').replace('Thursday', 'Torsdag').replace('Friday', 'Fredag').replace('Saturday', 'Lördag').replace('Sunday', 'Söndag')

      if (n == 0) dow = this.lang.l(190)
      if (n == 1) dow = this.lang.l(326)

      let d = {
        dayNumber: n,
        dow,
        available: false,
        slots: [],
        day: day
      }

      this.db.slots.push(d)
      this.createSlotItems(d, n)

      if (d.slots.length) d.available = true

    }

  }

  async selectDate(s) {

    this.selectedTime = {}

    if (!s.available) return

    if (this.selectedSlot.dayNumber == s.dayNumber) {
      this.selectedSlot = {}
      return
    }

    this.orders = await <any>this.db.httpPost('find', { system: this.db.system, table: 'orders', condition: { deliveryDate: s.day.format('YYYY-MM-DD'), "orderType.id": this.db.orderType.id }, projection: { _id: 0, products: 1, deliveryTime: 1 }, token: this.db.token })
    let capacityOverrides = await <any>this.db.httpPost('find', { system: this.db.system, table: 'capacityoverrides', condition: { workday: s.day.format('YYYY-MM-DD'), idOrderType: this.db.orderType.id }, projection: {  }, token: this.db.token })
    //console.log('orr', capacityOverrides)

    // Check orders per slot:

    // Reset
    s.slots.forEach(slot => { slot.orders = 0; slot.dishes = 0; slot.available = true })

    // Check overrides
    capacityOverrides.forEach(capacityOverride => {

      let slot = s.slots.find(slot => slot.start == capacityOverride.startMinute)

      if (slot) {
        if (capacityOverride.blocked) slot.available = false
        slot.maxOrders = capacityOverride.maxOrders
        slot.maxDishes = capacityOverride.maxDishes
      }
      
    })    

    // Loop orders
    this.orders.forEach(order => {

      let start = +order.deliveryTime.substr(0, 2) * 60 + +order.deliveryTime.substr(3, 2)

      // Find slot
      let _slot = s.slots.find(_slot => _slot.start == start)
      if (_slot) {

        // Check order count
        _slot.orders += 1
        if (_slot.maxOrders && _slot.orders >= _slot.maxOrders) _slot.available = false

        // Check dish count
        _slot.dishes += order.products.filter(op => op.isDish).reduce((acc, op) => acc += op.quantity, 0)
        if (_slot.maxDishes && _slot.dishes >= _slot.maxDishes) _slot.available = false

      }

    })



    this.selectedSlot = s    

    //console.log(s)

  }

  selectTime(t) {

    if(!t.available) return

    if (this.selectedTime.start == t.start) {
      this.selectedTime = {}
      return
    }

    this.selectedTime = t

    this.scrollToBottom()
    //console.log('Selected time', this.selectedTime)

  }  

  createSlotItems(slot, dayNumber) {

    slot.slots = []

    let cal = this.db.calendars.find(cal => cal.id == this.db.orderType.idCalendar)
    if (!cal) return

    let slotDateStartMoment = moment().add(dayNumber, 'days').startOf('day')
    let slotDateEndMoment = moment().add(dayNumber, 'days').endOf('day').add(3, 'hours')

    if (this.debug) console.log('1. Creating slots for day', dayNumber)

    //let timeSpans = this.db.parseCalendar(cal, slotDateStartMoment.format('YYYY-MM-DD'))
    let timeSpans: TimeSpan[] = this.db.getTimespans(cal, slotDateStartMoment.format('YYYY-MM-DD'))
    
    timeSpans.forEach(timeSpan => {
      
      //console.log('Timespan:', timeSpan)

      // Check preblocking days
      let dayBlock = false
      if (timeSpan.preBlockDays) {
        let dayDiff = slot.day.endOf('day').diff(moment(), 'days')
        if (dayDiff < timeSpan.preBlockDays) {
          slot.available = false
          dayBlock = true
        }
      }

      if (!dayBlock) this.addSlots(slot, timeSpan.startMinute, timeSpan.endMinute, timeSpan.interval || 0, timeSpan.floatingPreBlock || 15, timeSpan.maxDishes || 0, timeSpan.maxOrders || 0)

    })

    slot.slots = slot.slots.sort((a, b) => { if (a.start < b.start) return -1; if (a.start > b.start) return 1; return 0; })


  }

  addSlots(slot, from, to, interval, karens, maxDishes, maxOrders) {

    //console.log('->', slot)

    if (!from || !to || to < from || to - from > 1200 || interval > 1200) return console.log('Invalid value', from, to, interval)

    if (!interval) interval = 15

    let currentMinute = moment().hour() * 60 + moment().minute()

    let today = moment().format('YYYY-MM-DD')
    let slotDate = slot.day.format('YYYY-MM-DD')

    let first = true

    for (let n = from; n <= to; n += interval) {
      
      let skip = false
      if (slotDate == today && currentMinute + karens > n) skip = true
      
      if (!skip) {
        slot.slots.push({ start: n, available: true, orders: 0, dishes: 0, first: first, maxDishes, maxOrders })
        first = false
      }
    }
    
    if (slot.slots.length) {
      slot.slots[0].first = true
      slot.slots[slot.slots.length - 1].last = true
    }

  }

  async done() {

    if (this.debug) console.log(this.selectedSlot)

    let hour = Math.floor(this.selectedTime.start / 60)
    let minute = this.selectedTime.start - hour * 60
    
    this.selectedSlot.day.set('hour',  hour)
    this.selectedSlot.day.set('minute', minute)

    this.db.cart.deliveryDate = this.selectedSlot.day.format('YYYY-MM-DD')
    this.db.cart.deliveryTime = this.selectedSlot.day.format('HH:mm')
    this.db.cart.deliveryMinute = this.selectedTime.start

    this.db.setMealOfTheDay(this.selectedSlot.day.format('YYYY-MM-DD'))

    if (this.debug) console.log(this.db.cart)

    this.db.disableExcludedAddons()

    //this.db.step = this.db.orderType.type ? 30 : 40
    if (this.db.orderType.type == 1) {
      this.db.navigate('/address')
    } else {
      this.db.navigate('/products')
    }
    
  }

  scrollToBottom() {

    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight)
    }, 250)
    

  }

}
