import { Pipe, PipeTransform } from '@angular/core';
import { Product } from '../interfaces'
import { DbService } from '../services'

@Pipe({
  name: 'getProduct'
})
export class GetProductPipe implements PipeTransform {

  constructor(private db: DbService) {}

  transform(idProduct: string): Product {
    
    return this.db.products.find(p => p.id == idProduct)

  }

}
