<!-- Sticky div -->
<div class="sticky" [ngStyle]="{ 'font-family': db.fonts[0] }" [style]="1020 | customStyle : db.stylePipeHelper" (click)="cartClick()">

    <div class="rubrik">
  
        <div class="flac">
        
            <div class="noselect">
                
                <ng-container *ngIf="db.orderType.idLogo; else nologo">

                    <!--<img [src]="'https://db20.bokad.se/images/' + db.system + '/' + db.orderType.idLogo" class="logo" [style]="1028 | customStyle : db.stylePipeHelper"/>-->
                    <img [src]="'https://media.floworder.se/' + db.system + '/images/' + db.orderType.idLogo" class="logo" [style]="1028 | customStyle : db.stylePipeHelper"/>

                </ng-container>

                <ng-template #nologo>

                    <span>{{ (db.orderType | lang : 'displayName' : lang.ph) || (db.orderType | lang : 'name' : lang.ph) }}</span>
                    
                    <!--
                    <ng-container *ngIf="db.orderType.displayName; else else1">
                        <span>{{db.orderType.displayName}}</span>
                    </ng-container>
              
                    <ng-template #else1>
                        <span>{{ db.orderType | lang : 'name' : lang.ph }}</span>
                    </ng-template>
                    -->

                </ng-template>

            </div>
  
            <div *ngIf="!db.orderType.digitalMenuMode">
                <fa-layers [fixedWidth]="true" class="carticon">
                    <fa-icon [icon]="faShoppingCart"></fa-icon>
                    <fa-layers-counter [content]="db.productPipeHelper | cartCounter" style="font-size: 50px"></fa-layers-counter>
                </fa-layers>
            </div>
  
        </div>
  
    </div>

</div>
