import { Pipe, PipeTransform } from '@angular/core';
import { LangService } from '../services/lang.service'

@Pipe({
  name: 'l'
})
export class LPipe implements PipeTransform {

  constructor(private lang: LangService) {}

  transform(nr: number, pipeHelper: number): string {
    return this.lang.l(nr)
  }

}
