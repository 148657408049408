import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nl2br'
})
export class Nl2brPipe implements PipeTransform {

  transform(txt: string): string {
    return txt.split('\n').join('<br>')
  }

}
