import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router'
import { Cart, CartProduct, Product, ProductGroup, Vat, Configuration, Category, OrderType, Calendar, TimeSpan, Customer, CustomStyle, MealType, Member, Team } from '../interfaces'
import { v4 as uuid } from 'uuid'
import moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector'
import { RRule, RRuleSet, rrulestr } from 'rrule'

@Injectable({
  providedIn: 'root'
})
export class DbService {

  system = ''
  workday = ''
  isLoggedIn = false
  username = ''
  userlevel = 0
  token = ''
  cid = ''
  gtmId = ''
  gtmPurchaseSent = false

  idImage = ''
  ios = false

  deliveryAddress

  compact = false

  configuration = {} as Configuration

  dbUrl = 'https://db20.bokad.se/'
  //dbUrl = 'https://127.0.0.1:4201/'

  //step = 0
  categoryPipeHelper = 0
  productPipeHelper = 0

  products: Product[] = []
  addons = []

  categories: Category[] = []
  selectedCategories = ['']
  selCatProdIds = []
  categoryTree = []
  cart: Cart

  //cartProducts: CartProduct[] = []

  calendars = []
  mealOfTheDays = []
  mealOfTheWeeks = []
  vats: Vat[] = []
  productGroups: ProductGroup[] = []
  mealTypes: MealType[] = []
  allergies = []
  paymentTypes = []
  idDefaultPriceList

  fonts = ['Staatliches', 'Fira Sans', 'Roboto']
  showSticky = true
  topMargin = '60px'
  
  orderTypes: OrderType[] = []
  orderType: OrderType
  qrRanges = []
  qrCode = ''
  qrName = ''
  link = ''
  idCustomer = ''
  idOrderType = ''
  customer: Customer
  ttid = ''

  blurTime

  tipPercentages = []
  tipAmounts = []

  addressFields = []

  imageFolder = 'images'
  showInfoBox = true
  showInfoButton = true
  showQuantitySelector = true
  imageClickAdd = true
  showDescription = true
  showProductnameAbove = true

  noCategoriesMessage = false
  bypassCalendars = false
  slotInfo = false
  
  swishId = ''
  adyenRequest
  bamboraSession
  bizumSession
  paycometSession
  stripeUrl: string

  slots = []

  clientMessages = []

  teams: Team[]
  members: Member[]

  customStyles: CustomStyle[] = []
  stylePipeHelper = 0

  project: any
  projectName = ''
  memberName = ''
  
  constructor(private http: HttpClient, private deviceService: DeviceDetectorService, private router: Router, private route: ActivatedRoute) {

    this.cart = {
      id: uuid(),
      idCart: '',
      cid: '',
      //products: this.cartProducts,
      products: [],
      totalAmount: 0,
      totalAmountExcl: 0,
      totalVatAmount: 0,
      discountAmount: 0,
      discountAmountExcl: 0,
      discountVatAmount: 0,
      paymentMethod: 0,
      idPaymentType: '',
      paidAmount: 0,
      refundAmount: 0,
      ts: '',
      deliveryDate: '',
      deliveryTime: '',
      deliveryMinute: 0,
      delivered: false,
      printDate: '',
      printMinute: 0,
      printed: false,
      idCustomer: '',
      firstName: '',
      lastName: '',
      lastName2: '',
      phone: '',
      email: '',
      address1: '',
      address2: '',
      postCode: '',
      city: '',
      country: '',
      info: '',
      orderType: {
        id: '',
        idConfirmation: '',
        idPriceList: '',
        idPushoverGroup: '',
        type: 0,
        name: '',
        printerOverrides: []
      },
      activeLanguageCode: '',
      activeLocale: '',
      table: '',
      dishCount: 0,
      originalUrl: window.location.href,
      idTeam: '',
      idMember: '',
      discountCode: null,
      domicilioSocial: '',
      nif: '',
      razonSocial: '',
      qrCode: '',
      giftcardCode: '',
      giftcardSystem: '',
      deliveryInstructions: '',
      idProject: '',
      companyName: '',
      businessId: ''
    }

  }

  navigate(route: string) {

    // Route and preserve query parameters
    let qp = Object.assign({}, this.route.snapshot.queryParams)
    if (this.qrCode && !qp.qr) qp.qr = this.qrCode
    this.router.navigate([`${this.system}${route}`], { queryParams: qp }) 

  }

//  async login(system, username, password) {
//
//    this.httpPost('login', { system, username, password }).then(result => {
//      //console.log('Login -->', result)
//    }).catch()
//    
//  }

  httpPost(cmd, body) {

    var httpOptions = {
      headers: new HttpHeaders({
          'Accept':'application/json',
          'Content-Type': 'application/json'
      })
    }

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(`${this.dbUrl}${cmd}`, body, httpOptions)
        .toPromise()
        .then((res: any) => {
          resolve(res)
        },
          err => {
            reject(err)
          }
        )
    })

    return promise

  }

  getFonts() {
        
    this.fonts.filter(f => f && f != 'TrimMono').forEach((font, index) => {
      let link = document.createElement('link')
      link.id = 'fontlink' + index.toString()
      link.setAttribute('rel', 'stylesheet')
      link.setAttribute('href', `https://fonts.googleapis.com/css?family=${font.replace(' ', '+')}`)
      document.head.appendChild(link)  
    })

  }

  camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\-+/g, '');
  }

  setBg(idImage) {

    if (!idImage) return

    this.idImage = idImage

    if (this.deviceService.isMobile()) {
      setTimeout(() => {
        this.ios = true
      }, 400)
      return
    }

    //document.body.style.backgroundImage = `url("https://db20.bokad.se/images/${this.system}/${idImage}")`
    document.body.style.backgroundImage = `url("https://media.floworder.se/${this.system}/images/${idImage}")`
    document.body.style.backgroundPosition = 'center'
    document.body.style.backgroundRepeat = 'no-repeat'
    document.body.style.backgroundAttachment = 'fixed'    

    // Check custom style 1010
    let customStyle = this.customStyles.find(cs => cs.nr == 1010)
    if (customStyle) {
      customStyle.style.split(';').filter(style => style).forEach(style => {
        let styleKey = this.camelize(style.split(':')[0].trim())
        let styleValue = style.split(':')[1].trim()
        document.body.style[styleKey] = styleValue        
      })
    }

  }

  getDeviceInfo() {
    return this.deviceService.getDeviceInfo()
  }

  getPrice(idProduct, returnOrdPrice = false) {

    //console.log('getPrice', idProduct)

    let p = this.products.find(p => p.id == idProduct)
    if (!p) {
      //console.log('Product not found')
      return 9999 // Better to return this than 0
    }

    if (!p.prices.length) return 0 // Most reasonable thing to do. I think. Maybe not. But still. Reason: For example free addons that never have had a price set.

    // Use ordertypes default pricelist to start with
    let priceList = this.orderType.idPriceList

    //// old: let c = this.categories.find(c => c.id == this.selectedCategories[this.selectedCategories.length - 1])
    //// old: let c = this.categories.find(c => c.id == this.selectedCategories[1]) // no!
    //// old: if (c && c['idPriceList']) priceList = c['idPriceList']

    let workday = this.workday
    let deliveryMoment = moment()
    if (this.orderType.type == 0 && this.cart.deliveryDate && this.cart.deliveryTime) {
      workday = this.cart.deliveryDate
      deliveryMoment = moment(`${workday} ${this.cart.deliveryTime}`)
      //if (this.selectedTime?.asap) deliveryMoment = moment()
    }

    // Check for override pricelist, used for AW prices, Lunch beverages etc
    if (this.orderType.priceLists && this.orderType.priceLists.length) this.orderType.priceLists.forEach(otpl => {
      
      // Get calendar for pricelist 
      let cal: Calendar = this.calendars.find(c => c.id == otpl.idCalendar)
      if (cal) {
      
        // Get and check timespans from calendar
        let timeSpans = this.getTimespans(cal, this.cart.deliveryDate || this.workday)
        
        timeSpans.forEach(timeSpan => {
          
          // If current time is within calendar timespan
          if (deliveryMoment.isBetween(moment(timeSpan.startDate), moment(timeSpan.endDate), undefined, '[)')) {
            
            // Check if price exists for this pricelist
            let pp = p.prices.find(pp => pp.idPriceList == otpl.idPriceList)

            // If so, use this pricelist
            if (pp && pp.priceIncl) priceList = otpl.idPriceList

          }
        })

      }
    })

    let price = p.prices.find(price => price.idPriceList == priceList)
    if (!price || !price.priceIncl) {
      price = p.prices.find(price => price.idPriceList == this.idDefaultPriceList)
    }
    if (!price) {
      //console.log('No price found', p.name, this.idDefaultPriceList, p.prices)
      return 9999 // Better to return this than 0
    }

    //console.log('Returning price', price.priceIncl)
    return returnOrdPrice ? price.ordPriceIncl : price.priceIncl

  }

  getOrdPrice(idProduct) {

    let p = this.products.find(p => p.id == idProduct)
    if (!p) {
      return 0
    }

    let priceList = this.orderType.idPriceList
    //// old: let c = this.categories.find(c => c.id == this.selectedCategories[this.selectedCategories.length - 1])
    //// old: if (c && c['idPriceList']) priceList = c['idPriceList']

    let price = p.prices.find(price => price.idPriceList == priceList)
    if (!price) {
      return 0
    }

    return price.ordPriceIncl

  }

  cartTotal() {

    this.calculateCartTotals()
    return this.cart.totalAmount

    //let total = this.cart.products.reduce((acc, cp) => acc += cp.price * cp.quantity - cp.discountAmount, 0)
    //return total

  }

  calculateCartTotals() {

    // Reset totals
    this.cart.totalAmount = 0
    this.cart.totalAmountExcl = 0
    this.cart.totalVatAmount = 0

    // Reset discount totals
    this.cart.discountAmount = 0
    this.cart.discountAmountExcl = 0
    this.cart.discountVatAmount = 0

    // Check delivery cost
    if (this.orderType.deliveryCostFixed) {

      this.cart.products = this.cart.products.filter(cp => cp.idProduct != this.orderType.deliveryCostProductId)

      let deliveryProduct = this.products.find(p => p.id == this.orderType.deliveryCostProductId)
      
      if (deliveryProduct) {

        let deliveryCartProduct: CartProduct = {
          id: uuid(),
          cid: this.cid,
          type: 0,
          signature: '',
          idProduct: deliveryProduct.id,
          idProductGroup: deliveryProduct.idProductGroup,
          name: deliveryProduct.name,
          bongText: deliveryProduct.bongText,
          otherLanguages: deliveryProduct.otherLanguages,
          quantity: 1,
          unit: deliveryProduct.unit || '',
          idParent: '',
          price: this.orderType.deliveryCostFixed,
          priceExcl: 0,
          ordPrice: 0,
          vatPercent: 0,
          totalAmount: 0,
          totalAmountExcl: 0,
          totalVatAmount: 0,
          discountAmount: 0,
          discountAmountExcl: 0,
          discountVatAmount: 0,
          discountPercent: 0,
          isDish: false,
          isSotd: false,
          isStock: false,
          isTip: false,
          idPrinters: deliveryProduct.idPrinters,
          remoteId: deliveryProduct.remoteId || '',
          remoteQuantity: deliveryProduct.remoteQuantity || 1,
          mealType: null
        }

        this.cart.products.push(deliveryCartProduct)

      }

    }

    // Get amount or percent from discount code
    let discountAmount = this.cart.discountCode && this.cart.discountCode.amount ? this.cart.discountCode.amount.valueOf() : 0
    let discountPercent = this.cart.discountCode && this.cart.discountCode.percentage ? this.cart.discountCode.percentage.valueOf() : 0

    this.cart.products.forEach(cp => {

      let v: Vat = this.getVat(cp.idProduct)

      // Vat percentage
      cp.vatPercent = v ? v.vatPercent : 0

      // Price excl vat
      cp.priceExcl = cp.price / (1 + (cp.vatPercent / 100))

      // Total amount
      cp.totalAmount = cp.price * cp.quantity

      // Total amount excl vat
      cp.totalAmountExcl = cp.totalAmount / (1 + (cp.vatPercent / 100))

      // Total vat amount
      cp.totalVatAmount = cp.totalAmount - cp.totalAmountExcl

      // Check discount amount
      cp.discountAmount = 0
      cp.discountAmountExcl = 0
      cp.discountVatAmount = 0
      cp.discountPercent = 0
      if (discountAmount && !cp.isTip) {

        if (discountAmount >= cp.totalAmount) {

          discountAmount -= cp.totalAmount

          cp.discountAmount = cp.totalAmount.valueOf()
          cp.discountAmountExcl = cp.totalAmountExcl.valueOf()
          cp.discountVatAmount = cp.totalVatAmount.valueOf()

          cp.totalAmount = 0
          cp.totalAmountExcl = 0
          cp.totalVatAmount = 0

        } else {
          
          cp.totalAmount -= discountAmount
          cp.totalAmountExcl = cp.totalAmount / (1 + (cp.vatPercent / 100))
          cp.totalVatAmount = cp.totalAmount - cp.totalAmountExcl

          cp.discountAmount = discountAmount.valueOf()
          cp.discountAmountExcl = cp.discountAmount / (1 + (cp.vatPercent / 100))
          cp.discountVatAmount = cp.discountAmount - cp.discountAmountExcl

          discountAmount = 0

        }

      }

      // Check percentage discount
      if (discountPercent && !cp.isTip) {

        cp.discountAmount = cp.totalAmount * this.cart.discountCode.percentage / 100
        cp.discountAmountExcl = cp.discountAmount / (1 + (cp.vatPercent / 100))
        cp.discountVatAmount = cp.discountAmount - cp.discountAmountExcl
        cp.discountPercent = discountPercent

        cp.totalAmount -= cp.discountAmount
        cp.totalAmountExcl -= cp.discountAmountExcl
        cp.totalVatAmount -= cp.discountVatAmount

      }

      // Add to totals
      this.cart.totalAmount += cp.totalAmount
      this.cart.totalAmountExcl += cp.totalAmountExcl
      this.cart.totalVatAmount += cp.totalVatAmount

      this.cart.discountAmount += cp.discountAmount
      this.cart.discountAmountExcl += cp.discountAmountExcl
      this.cart.discountVatAmount += cp.discountVatAmount

    })

    if (this.configuration.roundingDigits == 0) this.cart.totalAmount = Math.round(this.cart.totalAmount)


  }

  getVat(idProduct: string): Vat {

    let p: Product = this.products.find(p => p.id == idProduct)
    if (!p) return null

    let pg: ProductGroup = this.productGroups.find(pg => pg.id == p.idProductGroup)
    if (!pg) return null

    let v: Vat = this.vats.find(v => v.id == pg.idVat)

    return v ? v : null

  }

  dishCount(): number {
    return this.cart.products.filter(cp => cp.isDish).reduce((acc, cp) => acc += cp.quantity, 0)
  }

  disableExcludedProducts() {

    if (!this.orderType || !this.orderType.excludedProducts) return

    this.orderType.excludedProducts.forEach(ep => {
      let p = this.products.find(p => p.id == ep.idProduct)
      if (p) p.active = false
    })

  }

  disableExcludedAddons() {

    if (!this.orderType || !this.orderType.excludedAddons) return

    //let currentMinute = moment().hour() * 60 + moment().minute()
    //console.log('1==>', this.cart.deliveryMinute, this.cart.deliveryDate)

    this.orderType.excludedAddons.forEach(ea => {

      if (ea.idCalendar) {

        let calendar = this.calendars.find(c => c.id == ea.idCalendar)
        if (calendar) {

          let timeSpans = this.getTimespans(calendar, this.cart.deliveryDate || this.workday)

          timeSpans.forEach(timeSpan => {

            if (this.cart.deliveryMinute >= timeSpan.startMinute && this.cart.deliveryMinute <= timeSpan.endMinute) {

              // Deactivate

              this.products.forEach(p => {
                p.addons.filter(pa => pa.idAddon == ea.idAddon).forEach(pa => { pa.active = false })
              })

            } 
            
          })          

        }

      } else {

        this.products.forEach(product => {
          product.addons = product.addons.filter(pa => pa.idAddon != ea.idAddon)
        })

      }

    })

  }
  
  disableExcludedAddonsOld() {

    if (!this.orderType || !this.orderType.excludedAddons) return

    this.orderType.excludedAddons.forEach(ea => {
      this.products.forEach(product => {
        product.addons = product.addons.filter(pa => pa.idAddon != ea.idAddon)
      })
    })

  }

  sortBy = (field, reverse = false) => {

    return (a, b) => {

      if (reverse) {
        let c = a
        a = b
        b = c
      }

      if (a[field] < b[field]) {
        return -1
      } else if (a[field] > b[field]) {
          return 1
      }

      return 0

    }
  }

  getTimespans(calendar, workday) {

    //if (calendar.id == '4093cf42-82b4-492b-b1ae-84a8978294b0') console.log('Get Timespans for', calendar.name, workday, calendar.id)

    // Make deep copy of calender to avoid changes in original object
    let c = JSON.parse(JSON.stringify(calendar))

    let returnTimespans = []

    c.timeSpans.forEach(timeSpan => {

      let startWorkday = moment(workday).startOf('day')
      let endWorkday = moment(workday).endOf('day').add(4, 'hours')

      let startTimespan = moment(timeSpan.startDate)
      let endTimespan = moment(timeSpan.endDate)

      let timeSpanDuration = endTimespan.diff(startTimespan, 'minutes')

      if (timeSpan.recurrenceRule) {

        // Create recurrence rule
        let rule = RRule.fromString(`DTSTART:${startTimespan.format('YYYYMMDD[T]HHmmss[Z]')}\nRRULE:${timeSpan.recurrenceRule}`)

        // Check if reccurence occurs on workday
        let r = rule.between(startWorkday.toDate(), endWorkday.toDate(), false)

        // If recurrence exists for workday, set timeSpan start and end to workday date
        if (r.length) {
          
          // Check recurrence exceptions
          let exc = timeSpan.recurrenceException ? timeSpan.recurrenceException.split(',').map(e => { return e.substr(0, 8) }).includes(startWorkday.format('YYYYMMDD')) : false

          if (!exc) {

            startTimespan.set({ year: startWorkday.year(), month: startWorkday.month(), date: startWorkday.date() })
            timeSpan.startDate = startTimespan.toDate()

            endTimespan = startTimespan.clone().add(timeSpanDuration, 'minutes') // Clone to avoid changing startTimespan
            timeSpan.endDate = endTimespan.toDate()

          }

        }

      }

      //if (calendar.id == '4093cf42-82b4-492b-b1ae-84a8978294b0' && startTimespan.isBetween(startWorkday, endWorkday)) console.log('Start is now', startTimespan.format('YYYY-MM-DD HH:mm:ss'))
      //if (calendar.id == '4093cf42-82b4-492b-b1ae-84a8978294b0' && startTimespan.isBetween(startWorkday, endWorkday)) console.log('End is now', endTimespan.format('YYYY-MM-DD HH:mm:ss'))

      // Return timespan if it is within workday
      if (startTimespan.isBetween(startWorkday, endWorkday)) returnTimespans.push(timeSpan)
      // Remove if timespan has recurrence exception
      if (timeSpan.recurrenceException && timeSpan.recurrenceException.split(',').map(e => { return e.substr(0, 8) }).includes(moment(workday).format('YYYYMMDD'))) returnTimespans.pop()

    })

    returnTimespans.forEach(ts => {

      ts.startMinute = moment(ts.startDate).hours() * 60 + moment(ts.startDate).minutes()
      ts.endMinute = moment(ts.endDate).hours() * 60 + moment(ts.endDate).minutes()      
      if (ts.endMinute <= 180) ts.endMinute += 1440

    })

    //console.log('Returning Timespans for', calendar.name, workday, returnTimespans)
    return returnTimespans
 
  }

  parseCalendar(calendar: Calendar, workday): TimeSpan[] {

    let returnTimeSpans: TimeSpan[] = []

    let startWorkday = moment(workday).startOf('day')
    let endWorkday = moment(workday).endOf('day').add(4, 'hours')

    calendar.timeSpans.forEach((timeSpan, index) => {

      //console.log(66,timeSpan)

      let startMoment = moment(timeSpan.startDate)
      let timeSpanDurationMinutes = moment(timeSpan.endDate).diff(moment(timeSpan.startDate), 'minutes')

      //console.log(calendar.name, timeSpan.text, index, 'dur', timeSpanDurationMinutes)
      //let endMoment = moment(timeSpan.endDate).add(5, 'hours')
      let fmt = 'YYYY-MM-DD HH:mm:ss'

      //let validRule = startWorkday.isBetween(startMoment, endMoment) && endWorkday.isBetween(startMoment, endMoment)
      //console.log(78, validRule)
      //console.log(startWorkday, startMoment, endMoment)

      //if (workday == '2021-06-04') console.log('Requested workday', workday, calendar.name, index, 'Timespan:', timeSpan.text, startMoment.format(fmt), endMoment.format(fmt), 'Recurrence', timeSpan.recurrenceRule, timeSpan)
      //if (workday == '2021-06-04') console.log('Requested workday', workday, calendar.name, index, 'Timespan:', timeSpan.text, startMoment.format(fmt), endMoment.format(fmt), 'Recurrence', timeSpan.recurrenceRule, timeSpan)

      // Check if workday is within timespan dates
      let within = false
      if (startMoment.isBetween(startWorkday, endWorkday)) {
        returnTimeSpans.push(timeSpan)
        within = true
        //console.log(2, 'Hit from timeSpan start and end date')
      }

      // Check if recurrence rule matches workday
      if (timeSpan.recurrenceRule && !within) {
        
        let rule = RRule.fromString(`DTSTART:${startMoment.format('YYYYMMDD[T]HHmmss[Z]')}\nRRULE:${timeSpan.recurrenceRule}`)      
        let r = rule.between(startWorkday.toDate(), endWorkday.toDate(), false)

        let add = true

        // Check recurrence exceptions
        if (timeSpan.recurrenceException) {
          let _exceptions = timeSpan.recurrenceException.split(',')
          _exceptions.forEach(_exception => {
            if (moment(_exception).format('YYYY-MM-DD') == workday) add = false
          })
        }
        
        if (r.length && add) {
            returnTimeSpans.push(timeSpan)
        }


      }

    })

    returnTimeSpans.forEach(ts => {
      
      // Correct start and end date
      ts.startDate = moment(`${workday} ${moment(ts.startDate).format('HH:mm:ss')}`).toDate()
      ts.endDate = moment(`${workday} ${moment(ts.endDate).format('HH:mm:ss')}`).toDate()

      // Provide start and end minute
      ts.startMinute = moment(ts.startDate).hours() * 60 + moment(ts.startDate).minutes()
      ts.endMinute = moment(ts.endDate).hours() * 60 + moment(ts.endDate).minutes()      
      if (ts.endMinute <= 180) ts.endMinute += 1440
      console.log('==>', ts.startMinute, ts.endMinute)
      

      if (ts.endMinute < ts.startMinute) {
        ts.endMinute += 1440
        ts.endDate = moment(`${moment(workday).add(1, 'day').format('YYYY-MM-DD')} ${moment(ts.endDate).format('HH:mm:ss')}`).toDate()
        //console.log('TS', ts)
      }

    })
    
    if (workday == '2021-07-01') console.log('Return', returnTimeSpans)
    return returnTimeSpans

  }

  itemCount(): number {
    
    return this.cart.products.filter(ci => !ci.idParent).reduce((acc, ci) => acc += ci.quantity, 0)

  }

  setupGtm() {

    if (!this.gtmId) return

    let s = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${this.gtmId}');`

    const script: any = document.createElement('script')
    script.type = 'text/javascript'
    script.text = s
    document.getElementsByTagName('head')[0].appendChild(script)

  }

  sendGtmStart() {
    
    if (!this.gtmId) return

    window['dataLayer'].push({'gtm.start': new Date().getTime(), event: 'gtm.js'})
    

  }

//  sendGtmPageView(url, title) {
//
//    let pageUrl = `${window.location.href}/${url}`
//    let pageTitle = `${title}`
//
//    let tag = {
//      event: 'virtualPageview',
//      pageUrl,
//      pageTitle 
//    }
//
//    //console.log('Sending virtualPageview tag', tag)
//
//    window['dataLayer'].push(tag)
//
//  }

  sendGtmAddToCart(id: string, name: string, quantity: number, price: number) {

    if (!this.gtmId) return
  
    let tag = {
      event: 'addToCart',
      ecommerce: {
        currencyCode: 'SEK',
        add: {                                
          products: [{                        
            name:  name,
            id: id,
            price: price.toString(),
            brand: this.system,
            category: 'web',
            variant: '',
            quantity: quantity
          }]
        }
      }
    }
    
    //console.log('Sending addToCart tag', tag)

    window['dataLayer'].push(tag)

  }

  sendGtmPurchase() {

    if (!this.gtmId || this.gtmPurchaseSent) return
    
    let tag =  {
      event : 'purchase', 
      ecommerce: {
        purchase: {
          actionField: {
            id: this.cart.id,
            affiliation: this.system,
            revenue: this.cart.totalAmount.toString().replace(/,/g, '.')
          },
          products: []
        }
      }
    }

    this.cart.products.forEach(cartProduct => {

      let tagProduct = {
        name: cartProduct.name,
        id: cartProduct.id,
        price: cartProduct.totalAmount.toString().replace(/,/g, '.'),
        brand: this.system,
        category: 'flow',
        variant: '',
        quantity: cartProduct.quantity
      }

      tag.ecommerce.purchase.products.push(tagProduct)

    })

    //console.log('GTM purchase', tag)

    window['dataLayer'].push(tag)
    this.gtmPurchaseSent = true

  }

  checkBlockedCategories() {
    this.categories.forEach(c => {
      let result = this.walkCat(c, 0)
      if (result.total > 0 && result.total == result.blocked) c.blockedTo = '9999'
      if (result.total > 0 && result.total != result.blocked) c.blockedTo = ''
    })
  }
  
  walkCat(category, iteration) {

    let blockStatus = {
      blocked: this.categories.filter(c => c.idParent == category.id && c.blockedTo).length,
      total: this.categories.filter(c => c.idParent == category.id).length
    }

    // Walk thru children
    this.categories.filter(c => c.idParent == category.id).forEach(c => {

      let result = this.walkCat(c, ++iteration)
      blockStatus.blocked += result.blocked
      blockStatus.total += result.total

    })

    //console.log(iteration)
    return blockStatus

  }

  async setMealOfTheDay(workday) {

    // Meal of the day
    this.mealOfTheDays = await <any>this.httpPost('find', { system: this.system, table: 'mealofthedays', condition: { workday: workday } })
    this.mealOfTheDays.forEach(motd => {
      let p = this.products.find(p => p.id == motd.idProduct)
      if (p && motd.name) p.name = motd.name
      if (p && motd.description) p.description = motd.description
      if (p && motd.price) {
        p.prices.forEach(pp => pp.priceIncl = motd.price)
      }
    })

    let year = moment(workday).year()
    let weekNumber = moment(workday).isoWeek()

    // Meal of the week
    this.mealOfTheWeeks = await <any>this.httpPost('find', { system: this.system, table: 'mealoftheweeks', condition: { year: year, weekNumber: weekNumber }})
    this.mealOfTheWeeks.forEach(motw => {
      let p = this.products.find(p => p.id == motw.idProduct)
      if (p && motw.name) p.name = motw.name
      if (p && motw.description) p.description = motw.description
      if (p && motw.price) {
        p.prices.forEach(pp => pp.priceIncl = motw.price)
      }
    })

    this.productPipeHelper++
    this.categoryPipeHelper++

  }

}
