import { Component, OnInit } from '@angular/core';
import { DbService, LangService } from '../../services'

@Component({
  selector: 'app-step40',
  templateUrl: './step40.component.html',
  styleUrls: ['./step40.component.scss']
})
export class Step40Component implements OnInit {

  constructor(public db: DbService, public lang: LangService) {

    if (this.db.orderType.idLogo) this.setTopMargin()
    
  }

  ngOnInit(): void {

    setTimeout(() => {
      window.scrollTo(0,0)
    }, 250)
    
  }

  async setTopMargin() {
    let i: any = await this.db.httpPost('findone', { system: this.db.system, table: 'images', condition: { id: this.db.orderType.idLogo }, token: this.db.token })
    this.db.topMargin = `${Math.round(i.height * 250 / i.width) + 20}px`
    if (this.db.system == 'grillkol') this.db.topMargin = '100px';
    if (this.db.system == 'foreningskryddor') this.db.topMargin = '80px';
  }

}
