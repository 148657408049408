import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'catpipe'
})
export class CatpipePipe implements PipeTransform {

  transform(categories: any[], idParent: string, pipeHelper: number): any[] {

    //console.log(categories, idParent)

    let r = categories.filter(c => c.idParent == idParent)

    if (!idParent) r = r.filter(r => r.active)

    return r.filter(r => !r.blockedTo)
    
  }

}
