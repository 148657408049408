import { Component, OnInit } from '@angular/core';
import { DbService } from '../../services'
import { Subscription } from 'rxjs'
import { ActivatedRoute } from '@angular/router'
import { Order } from '../../interfaces';
import moment from 'moment'

@Component({
  selector: 'app-update-address',
  templateUrl: './update-address.component.html',
  styleUrls: ['./update-address.component.scss']
})
export class UpdateAddressComponent implements OnInit {

  routeSubscription: Subscription
  idOrder = ''
  order: Order

  step = 0

  constructor(public db: DbService, private route: ActivatedRoute) {

    this.routeSubscription = this.route.params.subscribe(params => {

      if (params.system) this.db.system = params.system
      if (params.idOrder) this.idOrder = params.idOrder

      if (this.db.system && this.idOrder) this.setup()

    })

  }

  ngOnInit(): void {
  }

  async setup() {
    
    this.order = await <any>this.db.httpPost('findone', { system: this.db.system, table: 'orders', condition: { id: this.idOrder }, token: this.db.token })
    if (!this.order) return

    this.step = 1


  }

  async saveOrder() {
    
    //console.log(this.order)
    
    this.db.httpPost('updateone', { system: this.db.system, table: 'orders', condition: { id: this.order.id }, data: {
      phone: this.order['phone'],
      address1: this.order['address1'],
      address2: this.order['address2'],
      postCode: this.order['postCode'],
      city: this.order['city'],
      country: this.order['country']
    }, token: this.db.token }).then((result) => {})

    this.step = 2

  }


}
