<!--
<div style="padding: 5px; background-color: white; text-align: center; margin: 5px; display: inline-block; border-radius: 5px">{{currentLevel}} {{db.selectedCategories|json}}</div>
-->

<!-- Changed 210405
<div>
    <div *ngFor="let sc of db.selectedCategories; let i = index;">

        <div *ngIf="i != 0 && i != db.selectedCategories.length -1">
            <fa-stack>
                <fa-icon [icon]="faCircle" stackItemSize="2x" ></fa-icon>
                <fa-icon [icon]="faChevronDoubleDown" [inverse]="true" stackItemSize="1x"></fa-icon>
              </fa-stack>
        </div>
        
        <div class="section noselect">
           
            <div 
                *ngFor="let c of db.categories | catpipe : sc : db.categoryPipeHelper" 
                class="item" 
                [style.backgroundColor]="db.selectedCategories.includes(c.id) ? db.configuration.buttonSelectedColor : db.configuration.buttonColor"
                
                [style.color]="db.selectedCategories.includes(c.id) ? db.configuration.buttonSelectedTextColor : db.configuration.buttonTextColor"
                (click)="catClick(c, i)"
                [style]="1021 | customStyle : db.stylePipeHelper"
            >{{ c | lang : 'name' : lang.ph }}</div>

        </div>

    </div>
</div>

-->
<div style="max-width: 1600px; margin: 0 auto;">
    <div *ngFor="let sc of db.selectedCategories; let i = index;">

        <div *ngIf="i != 0 && i != db.selectedCategories.length -1">
            <fa-stack>
                <fa-icon [icon]="faCircle" stackItemSize="2x" ></fa-icon>
                <fa-icon [icon]="faChevronDoubleDown" [inverse]="true" stackItemSize="1x"></fa-icon>
              </fa-stack>
        </div>
        
        <!-- Category buttons. Only show if more than one. -->
        <ul class="ul1 clearfix" *ngIf="(db.categories | catpipe : sc : db.categoryPipeHelper).length > 1">
            <li 
                *ngFor="let c of db.categories | catpipe : sc : db.categoryPipeHelper" 
                class="li1"
                
            >
                <div
                    class="lidiv1"
                    [style.backgroundColor]="db.selectedCategories.includes(c.id) ? db.configuration.buttonSelectedColor : db.configuration.buttonColor"
                    [style.color]="db.selectedCategories.includes(c.id) ? db.configuration.buttonSelectedTextColor : db.configuration.buttonTextColor"
                    (click)="catClick(c, i)"
                    [style]="1021 | customStyle : db.stylePipeHelper"
                >{{ c | lang : 'name' : lang.ph }}</div>
            </li>
        </ul>

    </div>
</div>

<div *ngIf="db.noCategoriesMessage" class="nocats">{{ 266 | l : lang.ph }}</div>

<div class="catimg" *ngIf="categoryImage">
    <img [src]="'https://db20.bokad.se/images/' + db.system + '/' + categoryImage" class="img"/>
</div>
<!--
<div style="background-color: white;"><pre>{{db.selectedCategories|json}}</pre></div>
<div style="background-color: white;"><pre>{{db.selectedCategories|json}}</pre></div>
<div style="background-color: white;"><pre>{{db.categories|json}}</pre></div>
<div style="background-color: white;"><pre>{{db.selectedCategories|json}}</pre></div>
-->
