import { Pipe, PipeTransform } from '@angular/core';
import { DbService } from '../services'

@Pipe({
  name: 'ordPrice'
})
export class OrdPricePipe implements PipeTransform {

  constructor(private db: DbService) {}

  transform(idProduct: string): number {

    //return this.db.getOrdPrice(idProduct)
    return this.db.getPrice(idProduct, true)

  }

}
