import { Component, OnInit } from '@angular/core';
import { DbService, LangService } from '../../services'
import { faChevronDoubleDown } from '@fortawesome/pro-light-svg-icons'
import { faCircle } from '@fortawesome/free-solid-svg-icons'

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  currentLevel = 0
  categoryImage = ''

  faChevronDoubleDown = faChevronDoubleDown; faCircle = faCircle;

  constructor(public db: DbService, public lang: LangService) { 

  }

  ngOnInit(): void {
    
    let c = this.db.categories.filter(sc => sc.active && sc.idParent == this.db.selectedCategories[0])

    if (c.length) {
      this.catClick(c[0], 0)
    } else {
      this.db.noCategoriesMessage = true
    }

    
  }

  catClick(c, level) {

    //console.log(c.name, level)
    this.categoryImage = c.idImage || ''

    //this.db.selCatProdIds = []

    this.db.selectedCategories.length = level + 2

    if (!this.db.selectedCategories.includes(c.id)) {
      this.db.selectedCategories = this.db.selectedCategories.slice(0, level + 1)
      this.db.selectedCategories.push(c.id)
    } else {
      this.db.selectedCategories = this.db.selectedCategories.filter(sc => sc != c.id)
    }

    let l = this.db.selectedCategories.length

    if (l > 1) {
      let c = this.db.categories.find(c => c.id == this.db.selectedCategories[l - 1])
      if (c) this.db.selCatProdIds = c.products.filter(cp => cp.active).map(cp => cp.idProduct )
    }

    if (l != level + 2) { 
      this.catClick(c, level)
    }

    if (l == level + 2 && this.db.categories.filter(_c => _c.level == level + 1).length) {
      let _c = this.db.categories.find(_c => _c.level == level + 1 && _c.idParent == c.id)
      if (_c) this.catClick(_c, level + 1)
    }

    this.db.categoryPipeHelper++
    this.currentLevel = level

  }

}
