import { Component, OnInit } from '@angular/core';
import { DbService, LangService } from '../../services'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-step500',
  templateUrl: './step500.component.html',
  styleUrls: ['./step500.component.scss']
})
export class Step500Component implements OnInit {

  url: SafeResourceUrl

  constructor(public db: DbService, public sanitizer: DomSanitizer) {

    console.log(this.db.paycometSession)

    if (this.db.paycometSession.challengeUrl) {
      //this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.db.paycometSession.challengeUrl)
      //console.log(this.url)
      window.location.assign(this.db.paycometSession.challengeUrl)
    }

  }

  ngOnInit(): void {
  }

}
