import { Pipe, PipeTransform } from '@angular/core';
import { DbService } from '../services'
import { Product, Addon } from '../interfaces'

@Pipe({
  name: 'fromPrice'
})
export class FromPricePipe implements PipeTransform {

  constructor(private db: DbService) {}

  transform(product: Product): number {
    
//    let found = false
//    let price = 0
//
//    if (product.addons.filter(pa => pa.active).length) {
//
//      let pa = product.addons.filter(pa => pa.active)[0]
//
//      let addon: Addon = this.db.addons.find(a => a.id == pa.idAddon)
//      
//      addon.products.filter(ap => ap.active).forEach(ap => {
//
//        let apPrice = ap.overridePrice || 0
//        if (!apPrice) apPrice = this.db.getPrice(ap.idProduct)
//        
//        if (!found) {
//          price = apPrice
//          found = true
//        }
//
//        if (found && apPrice < price) price = apPrice
//
//      })
//
//    }
//
//    if (product.localAddons) product.localAddons.filter(la => la.active).forEach(la => {
//      la.products.filter(lap => lap.active).forEach(lap => {
//
//        let apPrice = lap.overridePrice || 0
//        if (!apPrice) apPrice = this.db.getPrice(lap.idProduct)
//
//        if (!found) {
//          price = apPrice
//          found = true
//        }
//
//        if (found && apPrice < price) price = apPrice
//
//      })
//    })
//
//    return found ? price : 0


//    let price = 0
//    if (product.localAddons.length) {
//
//      let localAddon = product.localAddons[0]
//
//      localAddon.products.filter(lap => lap.active).forEach(lap => {
//
//        let apPrice = lap.overridePrice || 0
//        if (!apPrice) apPrice = this.db.getPrice(lap.idProduct)
//
//        if (!price || apPrice < price) price = apPrice
//
//      })
//
//    }
//
//    let gPrice = 0
//    if (product.addons.length) {
//      
//      let pa = product.addons[0]
//
//    }
//

    let price = 0

    product.linkedProducts?.forEach(lp => {
      let lpPrice = lp.overridePrice || 0
      if (!lpPrice) lpPrice = this.db.getPrice(lp.idProduct)
      if (lp.free) lpPrice = 0
      price += lpPrice
    })

    let addons: Addon[] = []
    
    if (product.localAddons) addons = addons.concat(product.localAddons)

    product.addons.forEach(pa => {
      let addon: Addon = this.db.addons.find(a => a.id == pa.idAddon)
      if (addon) addons.push(addon)
    })

    addons.filter(a => a.minItems).forEach(a => {

      let lowestPrice = 0

      a.products.filter(ap => ap.active).forEach(ap => {
        let apPrice = ap.overridePrice || 0
        if (!apPrice) apPrice = this.db.getPrice(ap.idProduct)
        if (ap.free) apPrice = 0
        if (!lowestPrice || apPrice < lowestPrice) lowestPrice = apPrice
      })

      price += lowestPrice

    })

    return price

  }

}
