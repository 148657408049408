import { Pipe, PipeTransform } from '@angular/core';
import { DbService } from '../services'

@Pipe({
  name: 'price'
})
export class PricePipe implements PipeTransform {

  constructor(private db: DbService) {}

  transform(idProduct: string): number {

    return this.db.getPrice(idProduct)

  }

}
