import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { DbService } from './services/db.service'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(private db: DbService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    //console.log('CANACTIVATE', route, state)

    if (!this.db.system && route.params && route.params.system && route.queryParams && route.queryParams.qr) {
      this.router.navigate([`/${route.params.system}/qr`], { queryParams: route.queryParams })
    }
    
    if (!this.db.system && route.params && route.params.system && route.queryParams && route.queryParams.c) {
      this.router.navigate([`/${route.params.system}/c`], { queryParams: route.queryParams })
    }
    
    if (!this.db.system && route.params && route.params.system) {
      this.router.navigate([`/${route.params.system}`], { queryParams: route.queryParams })
    }

    return true

  }
  
}
