import { Component, OnInit } from '@angular/core';
import { DbService, LangService } from '../../services'

@Component({
  selector: 'app-step99',
  templateUrl: './step99.component.html',
  styleUrls: ['./step99.component.scss']
})
export class Step99Component implements OnInit {

  constructor(public db: DbService, public lang: LangService) {

    setTimeout(() => {
      if (this.db.orderType.returnUrl || this.db.configuration.returnUrl) {
        window.location.assign(this.db.orderType.returnUrl || this.db.configuration.returnUrl)
      } else {
        window.location.reload() 
      }
    }, 10000)

  }

  ngOnInit(): void {
  }

}
