import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ref'
})
export class RefPipe implements PipeTransform {

  //transform(objs: any[], id: string, fromObjs: any[]): any[] {
  //  console.log(objs, id, fromObjs)
  //  let r = []
  //  objs.forEach(o => {
  //    let fo = fromObjs.find(fo => fo.id == o[id])
  //    if (fo) r.push(fo)
  //  })
  //  return r
  //}

  transform(idValue: string, idField: string, returnField: string, objs: any[]): any {
    let o = objs.find(o => o[idField] == idValue)
    return o ? o[returnField] : null
  }

}
