import { Pipe, PipeTransform } from '@angular/core';
import { DbService } from '../services'

@Pipe({
  name: 'customStyle'
})
export class CustomStylePipe implements PipeTransform {

  constructor(private db: DbService) {}

  transform(styleNr: number, pipeHelper: number): string {
    let style = this.db.customStyles.find(s => s.nr == styleNr)
    return style ? style.style : ''
  }

}
