import { padStart } from './helpers';
/**
 * General date-related utilities.
 * Also handles several incompatibilities between JavaScript and Python
 *
 */
export var dateutil;
(function (dateutil) {
  dateutil.MONTH_DAYS = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  /**
   * Number of milliseconds of one day
   */
  dateutil.ONE_DAY = 1000 * 60 * 60 * 24;
  /**
   * @see: <http://docs.python.org/library/datetime.html#datetime.MAXYEAR>
   */
  dateutil.MAXYEAR = 9999;
  /**
   * Python uses 1-Jan-1 as the base for calculating ordinals but we don't
   * want to confuse the JS engine with milliseconds > Number.MAX_NUMBER,
   * therefore we use 1-Jan-1970 instead
   */
  dateutil.ORDINAL_BASE = new Date(Date.UTC(1970, 0, 1));
  /**
   * Python: MO-SU: 0 - 6
   * JS: SU-SAT 0 - 6
   */
  dateutil.PY_WEEKDAYS = [6, 0, 1, 2, 3, 4, 5];
  /**
   * py_date.timetuple()[7]
   */
  dateutil.getYearDay = function (date) {
    var dateNoTime = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
    return Math.ceil((dateNoTime.valueOf() - new Date(date.getUTCFullYear(), 0, 1).valueOf()) / dateutil.ONE_DAY) + 1;
  };
  dateutil.isLeapYear = function (year) {
    return year % 4 === 0 && year % 100 !== 0 || year % 400 === 0;
  };
  dateutil.isDate = function (value) {
    return value instanceof Date;
  };
  dateutil.isValidDate = function (value) {
    return dateutil.isDate(value) && !isNaN(value.getTime());
  };
  /**
   * @return {Number} the date's timezone offset in ms
   */
  dateutil.tzOffset = function (date) {
    return date.getTimezoneOffset() * 60 * 1000;
  };
  /**
   * @see: <http://www.mcfedries.com/JavaScript/DaysBetween.asp>
   */
  dateutil.daysBetween = function (date1, date2) {
    // The number of milliseconds in one day
    // Convert both dates to milliseconds
    var date1ms = date1.getTime() - dateutil.tzOffset(date1);
    var date2ms = date2.getTime() - dateutil.tzOffset(date2);
    // Calculate the difference in milliseconds
    var differencems = date1ms - date2ms;
    // Convert back to days and return
    return Math.round(differencems / dateutil.ONE_DAY);
  };
  /**
   * @see: <http://docs.python.org/library/datetime.html#datetime.date.toordinal>
   */
  dateutil.toOrdinal = function (date) {
    return dateutil.daysBetween(date, dateutil.ORDINAL_BASE);
  };
  /**
   * @see - <http://docs.python.org/library/datetime.html#datetime.date.fromordinal>
   */
  dateutil.fromOrdinal = function (ordinal) {
    return new Date(dateutil.ORDINAL_BASE.getTime() + ordinal * dateutil.ONE_DAY);
  };
  dateutil.getMonthDays = function (date) {
    var month = date.getUTCMonth();
    return month === 1 && dateutil.isLeapYear(date.getUTCFullYear()) ? 29 : dateutil.MONTH_DAYS[month];
  };
  /**
   * @return {Number} python-like weekday
   */
  dateutil.getWeekday = function (date) {
    return dateutil.PY_WEEKDAYS[date.getUTCDay()];
  };
  /**
   * @see: <http://docs.python.org/library/calendar.html#calendar.monthrange>
   */
  dateutil.monthRange = function (year, month) {
    var date = new Date(Date.UTC(year, month, 1));
    return [dateutil.getWeekday(date), dateutil.getMonthDays(date)];
  };
  /**
   * @see: <http://docs.python.org/library/datetime.html#datetime.datetime.combine>
   */
  dateutil.combine = function (date, time) {
    time = time || date;
    return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), time.getHours(), time.getMinutes(), time.getSeconds(), time.getMilliseconds()));
  };
  dateutil.clone = function (date) {
    var dolly = new Date(date.getTime());
    return dolly;
  };
  dateutil.cloneDates = function (dates) {
    var clones = [];
    for (var i = 0; i < dates.length; i++) {
      clones.push(dateutil.clone(dates[i]));
    }
    return clones;
  };
  /**
   * Sorts an array of Date or dateutil.Time objects
   */
  dateutil.sort = function (dates) {
    dates.sort(function (a, b) {
      return a.getTime() - b.getTime();
    });
  };
  dateutil.timeToUntilString = function (time, utc) {
    if (utc === void 0) {
      utc = true;
    }
    var date = new Date(time);
    return [padStart(date.getUTCFullYear().toString(), 4, '0'), padStart(date.getUTCMonth() + 1, 2, '0'), padStart(date.getUTCDate(), 2, '0'), 'T', padStart(date.getUTCHours(), 2, '0'), padStart(date.getUTCMinutes(), 2, '0'), padStart(date.getUTCSeconds(), 2, '0'), utc ? 'Z' : ''].join('');
  };
  dateutil.untilStringToDate = function (until) {
    var re = /^(\d{4})(\d{2})(\d{2})(T(\d{2})(\d{2})(\d{2})Z?)?$/;
    var bits = re.exec(until);
    if (!bits) throw new Error("Invalid UNTIL value: " + until);
    return new Date(Date.UTC(parseInt(bits[1], 10), parseInt(bits[2], 10) - 1, parseInt(bits[3], 10), parseInt(bits[5], 10) || 0, parseInt(bits[6], 10) || 0, parseInt(bits[7], 10) || 0));
  };
})(dateutil || (dateutil = {}));
export default dateutil;
