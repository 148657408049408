import { Pipe, PipeTransform } from '@angular/core';
import { DbService } from '../services'

@Pipe({
  name: 'productCount'
})
export class ProductCountPipe implements PipeTransform {

  constructor(private db: DbService) {}

  transform(idProduct: string, productPipeHelper: number): string {

    let counter = this.db.cart.products.filter(cp => !cp.idParent && cp.idProduct == idProduct).reduce((acc, cp) => { return acc + cp.quantity }, 0)
    
    return counter.toString()

  }

}
