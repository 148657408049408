import { Pipe, PipeTransform } from '@angular/core';
import { DbService, LangService } from '../services'

@Pipe({
  name: 'lang'
})
export class LangPipe implements PipeTransform {

  constructor(private lang: LangService) {}

  transform(obj, field, pipeHelper): string {

    // If default language...
    if (this.lang.activeLanguageCode == this.lang.defaultLanguageCode) {
      if (field == 'name' && obj.displayName) field = 'displayName' // Always use displayName instead of name if available
      return obj[field] || ''
    }

    // If no other languages use default language
    if (!obj.otherLanguages) return obj[field] || ''

    // Other languages
    let ol = obj.otherLanguages.find(ol => ol.languageCode == this.lang.activeLanguageCode)
    if (ol && ol[field]) {
      if (field == 'name' && ol.displayName) field = 'displayName' // Always use displayName instead of name if available
      return ol[field] || ''
    }

    // If no other languages found, return default language
    return obj[field] || ''

  }

}
