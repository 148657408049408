import { Pipe, PipeTransform } from '@angular/core';
import { OrderType } from '../interfaces'

@Pipe({
  name: 'selectOrderTypes'
})
export class SelectOrderTypesPipe implements PipeTransform {

  transform(orderTypes: OrderType[], hideAllBut: string, pipeHelper: number): OrderType[] {
    
    if (hideAllBut) return orderTypes.filter(ot => ot.id == hideAllBut)

    return orderTypes.filter(ot => ot.type < 2 && !ot.hidden).sort((a, b) => { if (a.sortorder < b.sortorder) return -1; if (a.sortorder > b.sortorder) return 1; return 0; })

  }

}
