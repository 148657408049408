import { Component, OnInit } from '@angular/core';
import { DbService, LangService } from '../../services'

@Component({
  selector: 'app-step60',
  templateUrl: './step60.component.html',
  styleUrls: ['./step60.component.scss']
})
export class Step60Component implements OnInit {

  constructor(public db: DbService, public lang: LangService) {

    setTimeout(() => {
      if (this.db.orderType.returnUrl || this.db.configuration.returnUrl) {
        window.location.assign(this.db.orderType.returnUrl || this.db.configuration.returnUrl)
      } else {
        window.location.reload() 
      }
    }, 20000)

  }

  ngOnInit(): void {
  }

}
