import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minToTime'
})
export class MinToTimePipe implements PipeTransform {

  transform(minutes: number, duration?: number): string {
    
    if (!minutes) return ''

    if (minutes > 1439) minutes -= 1440

    let time = Math.floor(minutes / 60).toString().padStart(2, '0') + ":" + (minutes - (Math.floor(minutes / 60) * 60)).toString().padStart(2, '0')
    
    if (duration) {
      var toTime = minutes+duration
      if (toTime > 1439) toTime -= 1440
      time += " - " + Math.floor(toTime / 60).toString().padStart(2, '0') + ":" + (toTime - (Math.floor(toTime / 60) * 60)).toString().padStart(2, '0')
    }

    return time
  }

}
