<div class="outer">

    <!-- Logo -->
    <div *ngIf="db.configuration.idLogotype" class="logodiv">
        <!--<img class="logo" [src]="'https://db20.bokad.se/images/' + db.system + '/' + db.configuration.idLogotype"/>-->
        <img class="logo" [src]="'https://media.floworder.se/' + db.system + '/images/' + db.configuration.idLogotype"/>
    </div>

    <!-- Heading -->
    <div class="heading noselect" [style.font-family]="db.fonts[0]">{{ 323 | l : lang.ph }}</div>

    <!-- Date slots -->
    <div class="itemdiv" [class.item1]="(db.slots | showDate : db.orderType.showUnavailableDays).length == 1" [class.item2]="(db.slots | showDate : db.orderType.showUnavailableDays).length == 2">

        <div 
            *ngFor="let s of db.slots | showDate : db.orderType.showUnavailableDays" 
            
            (click)="selectDate(s)" 
            [class.selecteddate]="selectedSlot.dayNumber == s.dayNumber" 
            class="slot noselect" 
            [class.unavailable]="!s.available" >
            {{s.dow}}
        </div>

    </div>

    <!-- Time slots -->
    <div *ngIf="selectedSlot.dow" class="heading noselect" [style.font-family]="db.fonts[0]">{{ 325 | l : lang.ph }}</div>

    <div *ngIf="selectedSlot.dow" class="itemdiv r4">
        <div *ngFor="let t of selectedSlot.slots" class="slot noselect" [class.first]="t.first" (click)="selectTime(t)" [class.selecteddate]="selectedTime.start == t.start" [class.unavailable]="!t.available">
            <div>{{t.start | minToTime}}</div>
            <div *ngIf="db.slotInfo">
                <table style="margin: 0 auto;">
                    <tr><td>O:</td><td class="ar">{{t.orders}}</td><td class="ar">{{t.maxOrders}}</td></tr>
                    <tr><td>D:</td><td class="ar">{{t.dishes}}</td><td class="ar">{{t.maxDishes}}</td></tr>
                </table>
            </div>
        </div>
    </div>

    <!-- Proceed button -->
    <div class="btndiv">
        <button *ngIf="selectedTime.start" mat-raised-button  (click)="done()">{{ 159 | l : lang.ph }}</button>
    </div>

</div>

<!--<pre style="background-color: white; text-align: left;">{{selectedSlot|json}}</pre>-->