import { Pipe, PipeTransform } from '@angular/core';
import { DbService } from '../services'
import { Product, Addon } from '../interfaces'

@Pipe({
  name: 'showFromLabel'
})
export class ShowFromLabelPipe implements PipeTransform {

  constructor(private db: DbService) {}

  transform(product: Product): boolean {

    let addons: Addon[] = []
    
    if (product.localAddons) addons = addons.concat(product.localAddons)

    product.addons.forEach(pa => {
      let addon: Addon = this.db.addons.find(a => a.id == pa.idAddon)
      if (addon) addons.push(addon)
    })

    if (addons.length == addons.filter(a => a.hidden).length) return false

    return true;
  }

}
