<div *ngIf="db.ios" class="ios" [style.background-image]="'url(https://media.floworder.se/' + db.system + '/images/' + db.idImage + ')'">
    <!--<img [src]="'https://db20.bokad.se/images/' + db.system + '/' + db.idImage">-->
</div>

<div style="display: flex; justify-content: space-between;">

    <div>&nbsp;</div>
    <!--
    <div *ngIf="1 + 1 == 3" class="friends" (click)="inviteFriends()">
        <fa-icon [icon]="faUserFriends"></fa-icon>
    </div>
    <div *ngIf="lang.languages.length > 1" class="langs">
        <div class="langdiv" (click)="lang.setNextLang()"><img [src]="'/assets/' + lang.activeLanguageCode + '.png'" class="langicon"></div>
    </div>
    -->
    <div *ngIf="lang.languages.length > 1">

        <button mat-icon-button [matMenuTriggerFor]="menu" class="menu">
            <!--<mat-icon>more_vert</mat-icon>-->
            <div><img [src]="'/assets/' + lang.activeLanguageCode + '.png'" class="menuicon"></div>
        </button>

        <mat-menu #menu="matMenu">

            <button *ngFor="let l of lang.languages" (click)="lang.setLang(l.languageCode)" mat-menu-item [class.mbselected]="l.languageCode == lang.activeLanguageCode">
                <div class="menubuttondiv">
                    <div><img [src]="'/assets/' + l.languageCode + '.png'" class="langicon"></div>
                    <div class="mbname">{{l.name}}</div>
                </div>
            </button>

        </mat-menu>

    </div>

</div>

<div class="outer tc">
    <router-outlet></router-outlet>
    <div *ngIf="db.system == 'demo1'" class="flowlogo">
        <div class="flowlogoinner">
            <a href="https://floworder.se"><img src="/assets/flowlogo.png"></a>
        </div>
    </div>
</div>



<div class="info" *ngIf="db.configuration.name">
    <div class="innerinfo">
        <div>&copy; {{year()}} {{this.db.configuration.name}}</div>
        <div class="terms" (click)="termsClick()">{{ 256 | l : lang.ph }}</div>
    </div>
</div>

<div id="cookieConsent" *ngIf="showConcent">
    <!--<div id="closeCookieConsent">X</div>-->
    {{ 261 | l : lang.ph }} <span class="concentmoreinfo" (click)="termsClick()">{{ 262 | l : lang.ph }}</span> <span class="cookieConsentOK" (click)="concentClick()">{{ 263 | l : lang.ph }}</span>
</div>

<ng-template #termsRef let-terms>

    <div style="text-align: right;">
        <button mat-icon-button class="close-button" [mat-dialog-close]="true">
            <mat-icon class="close-icon" color="warn">close</mat-icon>
        </button>
    </div>  

    <mat-dialog-content>
        <div *ngIf="terms" [innerHTML]="terms | sanitizeHtml"></div>
    </mat-dialog-content>

    <div mat-dialog-actions>
        <div style="margin: 0 auto">
            <button mat-raised-button mat-dialog-close color="primary">Ok</button>
        </div>
    </div>

</ng-template>

<ng-template #inviteRef>

    <div style="text-align: right;" class="closex">
        <button mat-icon-button class="close-button" [mat-dialog-close]="true">
            <mat-icon class="close-icon" color="warn">close</mat-icon>
        </button>
    </div>  

    <mat-dialog-content>
        Här kan du bjuda in dina bordsgrannar så att de kan lägga produkter i din varukorg, perfekt om ni är flera som vill beställa tillsammans.<br><br>
        Be de andra personerna att skanna samma QR-kod som du innan du skickar inbjudan. Du godkänner själv vilka som får beställa via dig.
    </mat-dialog-content>

    <div mat-dialog-actions>
        <div style="margin: 0 auto">
            <button mat-raised-button mat-dialog-close color="primary">Skicka inbjudan</button>
        </div>
    </div>

</ng-template>

<div id="overlay" *ngIf="showOverlay">234</div> 