import { Component, OnInit, ViewChild } from '@angular/core';
import { DbService, LangService } from '../../services'

@Component({
  selector: 'app-step400',
  templateUrl: './step400.component.html',
  styleUrls: ['./step400.component.scss']
})
export class Step400Component implements OnInit {

  @ViewChild('payForm') payForm

  enableBizum = false

  constructor(public db: DbService) { }

  ngOnInit(): void {
    
    console.log(this.db.bizumSession)
    
    this.enableBizum = true

    setTimeout(() => {
      this.payForm.nativeElement.submit()
    }, 250)

  }

}
