import { Pipe, PipeTransform } from '@angular/core';
import { DbService } from '../services'

@Pipe({
  name: 'cartTotal'
})
export class CartTotalPipe implements PipeTransform {

  constructor(private db: DbService) {}

  transform(pipeHelper: number): number {
    
    return this.db.cartTotal()
    
  }

}
