import { Component, OnInit } from '@angular/core';
import { DbService, LangService } from '../../services'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { DeviceDetectorService } from 'ngx-device-detector'

@Component({
  selector: 'app-step200',
  templateUrl: './step200.component.html',
  styleUrls: ['./step200.component.scss']
})
export class Step200Component implements OnInit {

  faCircleNotch = faCircleNotch  

  showOpenSwish = false
  swishOpened = false

  timer

  constructor(public db: DbService, public lang: LangService, private deviceService: DeviceDetectorService) {

    if (this.deviceService.isMobile() || this.deviceService.isTablet()) this.showOpenSwish = true

    setTimeout(() => {
      window.location.reload()
    }, 5 * 60 * 1000)

    this.timer = setInterval(() => {
      this.checkOrder()
    }, 1500)

  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    clearInterval(this.timer)
  }

  openSwish() {
    this.swishOpened = true
    window.open('swish://')
  }

  cancelSwish() {
    //this.db.step = 50
    let result = this.db.httpPost('swishcancel', { id: this.db.swishId })
    this.db.navigate('/cart')
    //console.log('Cancel result', result)
  }

  async checkOrder() {

    let order = await <any>this.db.httpPost('findone', { system: this.db.system, table: 'orders', condition: { idCart: this.db.cart.id }, token: this.db.token })

    if (order) {
      clearInterval(this.timer)
      this.db.navigate('/paysuccess')
    }

  }

}
