import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'subObj'
})
export class SubObjPipe implements PipeTransform {

  transform(id: string, objs: any[]): any {
    return objs.find(o => o.id == id)
  }

}
