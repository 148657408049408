import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'subTable'
})
export class SubTablePipe implements PipeTransform {

  transform(ids: string[], subTable: any[]): any[] {
    let r = []
    return subTable.filter(st => ids.includes(st.id))
  }

}
