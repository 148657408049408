import { Component, OnInit, HostListener, ViewChild, TemplateRef } from '@angular/core';
import { DbService, LangService, MqttService } from './services'
import { Subscription } from 'rxjs'
import { Router, NavigationEnd, NavigationStart } from '@angular/router'
import { v4 as uuid } from 'uuid'
import moment from 'moment'
//import { NgxSmartModalService } from 'ngx-smart-modal';
import { RRule, RRuleSet, rrulestr } from 'rrule'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatDialog } from '@angular/material/dialog';
import { faUserFriends } from '@fortawesome/pro-light-svg-icons'
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  @ViewChild("termsRef") termsRef: TemplateRef<any>;
  @ViewChild("inviteRef") inviteRef: TemplateRef<any>;
  @HostListener('window:focus', ['$event']) onFocus(event: FocusEvent): void { this.focus() }
  @HostListener('window:blur', ['$event']) onBlur(event: FocusEvent): void { this.blur() }

  faUserFriends = faUserFriends

  debug = false
  showConcent = false
  showOverlay = false

  mqttSubscription: Subscription

  constructor(public db: DbService, public lang: LangService, private router: Router, private mqtt: MqttService, public snackBar: MatSnackBar, public dialog: MatDialog) {

    //console.log(window.location.pathname)
    //return

    // localhost:4200/FNLGP/34PQS
    // localhost:4200/NB2V4/K4S9C

    //if (window.location.hostname.includes('localhost') && !window.location.href.includes('grillkol')) window.location.assign('/grillkol/shop' + window.location.pathname)
    //if (window.location.hostname.includes('localhost') && !window.location.href.includes('foreningsforsaljning')) window.location.assign('/foreningsforsaljning/shop' + window.location.pathname)

    if (window.location.hostname.includes('kaffeshop.nu') && !window.location.href.includes('foreningsforsaljning')) window.location.assign('/foreningsforsaljning/shop' + window.location.pathname)
    if (window.location.href.includes('kaffeshop.nu/registrering')) window.location.assign('https://backoffice.floworder.se/custom/foreningsforsaljning')

    if (window.location.hostname.includes('grillkol.se') && !window.location.href.includes('grillkol/')) window.location.assign('/grillkol/shop' + window.location.pathname)
    
    //console.log(window.location)

    // Cookie concent
    let cc = localStorage.getItem('cookieconcent')
    if (!cc) this.showConcent = true

    if (this.getQueryVariable('cal') == 'bypass') this.db.bypassCalendars = true
    if (this.getQueryVariable('slot') == 'info') this.db.slotInfo = true

    if (this.debug) console.log('This is constructor')

    this.db.blurTime = moment()

    // Suicide
    setTimeout(() => {
      this.snackBar.open(this.lang.l(285), '', { duration: 3000, panelClass: 'green-snackbar' })
      setTimeout(() => { 
        if (this.db.orderType.returnUrl || this.db.configuration.returnUrl) {
          window.location.assign(this.db.orderType.returnUrl || this.db.configuration.returnUrl)
        } else {
          window.location.reload() 
        }
      }, 70000)
    }, 10 * 60 * 1000)

    this.router.events.subscribe(event => {

      // GTM Start nav
      if (event instanceof NavigationEnd && this.db.gtmId) {
        this.db.sendGtmStart()
      }

      // Back button detection
      if (event instanceof NavigationStart && event.restoredState) {
        
        if (this.db.orderType.returnUrl || this.db.configuration.returnUrl) {
          this.showOverlay = true
          window.location.assign(this.db.orderType.returnUrl || this.db.configuration.returnUrl)
        }
        
      }

    })

  }

  private unsubscriber : Subject<void> = new Subject<void>();

  ngOnInit(): void {

    history.pushState(null, '');

    fromEvent(window, 'popstate').pipe(
      takeUntil(this.unsubscriber)
    ).subscribe((_) => {
      history.pushState(null, '');
      window.location.reload()
    });

  }

  ngOnDestroy() {
    this.mqttSubscription.unsubscribe()
    this.unsubscriber.next();
    this.unsubscriber.complete();
  } 

  focus() {

    //console.log('Focus')

    if (this.db.blurTime) {
      
      var duration = moment.duration(moment().diff(this.db.blurTime))
      var minutes = duration.minutes()
      var seconds = duration.seconds()
      //console.log('Blurtime', minutes, seconds)
      if (minutes > 2) window.location.reload()

    }
    
  }

  blur() {

    //console.log('Focus')

    this.db.blurTime = moment()

  }

  year = () => {
    return moment().format('YYYY')
  }

  termsClick() {
    this.dialog.open(this.termsRef, { data: this.db.configuration.terms, position: { top: '5%' }, maxHeight: '90%' })
  }

  concentClick() {
    this.showConcent = false
    localStorage.setItem('cookieconcent', '1')
  }

  getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) == variable) {
            return decodeURIComponent(pair[1]);
        }
    }
    return null
  }

  inviteFriends() {
    console.log('invite friends')
    this.dialog.open(this.inviteRef, { position: { top: '5%' }, maxHeight: '90%' })
  }  

}
