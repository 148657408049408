import { Component, OnInit } from '@angular/core';
import { DbService, LangService } from '../../services'
import { ModalCheckout, Event as BamboraEvent } from '@bambora/checkout-sdk-web'

@Component({
  selector: 'app-step300',
  templateUrl: './step300.component.html',
  styleUrls: ['./step300.component.scss']
})
export class Step300Component implements OnInit {

  spin = false

  constructor(public db: DbService, public lang: LangService) {
    console.log(this.db.bamboraSession)
  }

  ngOnInit(): void {
    if (this.db.bamboraSession && this.db.bamboraSession.token) this.bamboraCheckout(this.db.bamboraSession.token)
  }

  bamboraCheckout(token) {

    var checkout = new ModalCheckout(token)

    checkout.on(
      BamboraEvent.Authorize,
      function(payload) {
        //console.log('Authorize', payload.data.txnid);
      }
    )

    checkout.on(BamboraEvent.Cancel, () => {
      this.spin = false
      //console.log('Cancel')
      //this.db.step = 50
      this.db.navigate('/cart')
    })
  
    setTimeout(() => { this.spin = false }, 1000)
    checkout.show()

  }

}
