<div class="outer">

    <div *ngIf="db.configuration.idLogotype" class="logodiv">
        <!--<img class="logo" [src]="'https://db20.bokad.se/images/' + db.system + '/' + db.configuration.idLogotype"/>-->
        <img class="logo" [src]="'https://media.floworder.se/' + db.system + '/images/' + db.configuration.idLogotype"/>
    </div>

    <div class="heading" [style.font-family]="db.fonts[0]">{{ 335 | l : lang.ph }}</div>
    
    <div class="adrinput">
        <input class="input" type="text" (focus)="gotFocus()" [(ngModel)]="autocompleteInput" #addresstext style="padding: 12px 10px; border: 1px solid #ccc; max-width: 400px; width: 90%; font-size: 16px">
    </div>

    <div *ngIf="lang.defaultLanguageCode == 'es' && proceed" style="width: 100%; max-width: 400px; padding: 12px;">

        <div class="heading">
            <div>Detailed delivery instructions</div>
            <div style="font-size: 12px;">Please enter detailed instructions for delivery.</div>
            <textarea style="width: 100%; padding-top: 12px; max-width: 362px" rows="5" [(ngModel)]="db.cart.deliveryInstructions"></textarea>
        </div>

    </div>

    <div class="btndiv">
        <button *ngIf="proceed" mat-raised-button color="primary" (click)="doProceed()">{{ 159 | l : lang.ph }}</button>
    </div>

</div>


    
    
