import { Component, ViewChild, EventEmitter, Output, OnInit, Input, NgZone, AfterViewInit } from '@angular/core';
import { DbService, LangService } from '../../services'
import { MatSnackBar } from '@angular/material/snack-bar'

@Component({
  selector: 'app-step30',
  templateUrl: './step30.component.html',
  styleUrls: ['./step30.component.scss']
})
export class Step30Component implements OnInit, AfterViewInit {

  //@Input() adressType: string
  //@Input() searchLat: number
  //@Input() searchLng: number
  //@Input() searchRadius: number
  //@Input() maxKm: number
  //@Input() restaurantPlaceId: string
  //@Output() setAddress: EventEmitter<any> = new EventEmitter()
  //@Output() setError: EventEmitter<any> = new EventEmitter();

  @ViewChild('addresstext') addresstext: any;

  autocompleteInput: string;
  //queryWait: boolean;

  options = {}

  proceed = false

  constructor(public db: DbService, public lang: LangService, private zone: NgZone, public snackBar: MatSnackBar) {}

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }

  public handleAddressChange(address) {
    console.log(address)
  }

  private getPlaceAutocomplete() {

    const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement, { componentRestrictions: { country: this.lang.defaultLanguageCode.toUpperCase() }, types: [] });
    let circle = new google.maps.Circle({center: { lat: this.db.orderType.deliveryCenterLat, lng: this.db.orderType.deliveryCenterLng }, radius: this.db.orderType.deliveryCenterRadius * 1000 })
    autocomplete.setBounds(circle.getBounds())
    autocomplete.setOptions({strictBounds: true})

    google.maps.event.addListener(autocomplete, 'place_changed', () => {

      const place = autocomplete.getPlace()

      let service = new google.maps.DistanceMatrixService()
      let origin: google.maps.Place = { placeId: this.db.orderType.deliveryPlaceId }
      let dest: google.maps.Place = { placeId: place.place_id }

      let travelMode = google.maps.TravelMode.DRIVING
      if (this.db.system == 'banusburger') travelMode = google.maps.TravelMode.WALKING

      service.getDistanceMatrix({ origins: [origin], destinations: [dest], travelMode: travelMode }, (response, status) => {

        let distance = 0
        let duration = 0

        if (status == 'OK') {
          response.rows.forEach(row => {
            row.elements.forEach(element => {
              distance += element.distance.value
              duration += element.distance.value
            })
          })
        }

        place['distance'] = distance
        place['duration'] = duration

        console.log('distance', distance)
   
        if (distance / 1000 < this.db.orderType.deliveryCenterRadius) {

          // Success
          this.zone.run(() => this.invokeEvent(place))

        } else {
          //this.zone.run(() => this.invokeError('Tyvärr ligger adressen utanför vårt utkörningsområde'))
          this.snackBar.open(this.lang.l(336), '', { duration: 2000, panelClass: 'green-snackbar' })
        }

      })

    })

  }

  gotFocus() {
    this.proceed = false
  }

  invokeEvent(adr: any) {
    
    this.db.deliveryAddress = null
    this.db.cart.deliveryInstructions = ''

    if (this.lang.defaultLanguageCode == 'es') {

      this.db.deliveryAddress = adr

      this.proceed = true

      return

    }

    if (adr.types && adr.types.length && (adr.types.includes('street_address') || adr.types.includes('premise'))) {
    
      this.db.deliveryAddress = adr

      this.proceed = true

    } else {

      this.snackBar.open(this.lang.l(337), '', { duration: 2000 })

    }

  }

  invokeError(msg: string) {
    //this.setError.emit(msg)
  }

  doProceed() {
    this.db.navigate('/products')
  }

}
