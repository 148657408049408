import { Pipe, PipeTransform } from '@angular/core';
import { DbService } from '../services'
import { CartProduct } from '../interfaces'

@Pipe({
  name: 'cartChildren'
})
export class CartChildrenPipe implements PipeTransform {

  constructor(private db: DbService) {}

  transform(idParent: string): CartProduct[] {
    return this.db.cart.products.filter(cp => cp.idParent == idParent)
  }

}
