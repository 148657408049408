import { Injectable } from '@angular/core';
import { IMqttMessage, MqttService as _MqttService } from 'ngx-mqtt';
import { Observable, Subject, Subscription } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MqttService {

  private mqttSubject = new Subject<any>()
  private subscriptions: Subscription[] = []

  constructor(private _mqttService: _MqttService) {}

  public subscribe(topic: string) {
    this.subscriptions.push(this._mqttService.observe(topic).subscribe((message: IMqttMessage) => { this.mqttSubject.next({ topic: topic, message: message }) }))
  }

  mqttGetMessage(): Observable<any> {
    return this.mqttSubject.asObservable()
  }

  public unsafePublish(topic: string, message: string): void {
    this._mqttService.unsafePublish(topic, message, {qos: 0, retain: false })
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe())
  }

}
