import { Component, OnInit } from '@angular/core';
import { DbService, LangService, MqttService } from '../../services'
import { ActivatedRoute } from '@angular/router'
import { Subscription } from 'rxjs';
import { Configuration } from 'src/app/interfaces';

@Component({
  selector: 'app-pay-result',
  templateUrl: './pay-result.component.html',
  styleUrls: ['./pay-result.component.scss']
})
export class PayResultComponent implements OnInit {

  routeSubscription: Subscription
  configuration: Configuration
  reference = ''
  showMessage = 0

  constructor(public db: DbService, private route: ActivatedRoute, public lang: LangService) { 

    //console.log('Pay result')

    // Wait for route info, then login
    this.routeSubscription = this.route.params.subscribe(params => {      
    
      //console.log('Params', params)

      if (params.system && params.reference) {
        this.db.system = params.system
        this.reference = params.reference
        this.setup()
      }

    })

  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe()
  }   

//  async getPayment(id) {
//
//    let payments = await <any>this.db.httpPost('find', { system: 'global', table: 'bizumlog', condition: {  }, projection: {  }, token: this.db.token })
//    console.log(payments)
//  }

  async setup() {

    this.configuration = await <any>this.db.httpPost('findone', { system: this.db.system, table: 'configuration', condition: { id: '1' }, token: this.db.token })
    //console.log(this.configuration)

    let defaultLanguage = await <any>this.db.httpPost('findone', { system: this.db.system, table: 'languages', condition: { default: true }, token: this.db.token })

    this.lang.translations = await <any>this.db.httpPost('find', { system: 'global', table: 'translations', condition: { languageCode: defaultLanguage.languageCode, client: true }, token: this.db.token })

    this.lang.setLang(defaultLanguage.languageCode)

    this.db.setBg(this.configuration.idBackground)

//    // Get bizum request
//    let bizumlog1 = await <any>this.db.httpPost('findone', { system: this.db.system, table: 'bizumlog', condition: { id: this.reference }, token: this.db.token })
//    if (!bizumlog1) return
//
//    // Get bizum payment (if available)
//    let bizumlog2 = await <any>this.db.httpPost('findone', { system: this.db.system, table: 'bizumlog', condition: { "result.Ds_Order": this.reference, "result.Ds_Response": '0000' }, token: this.db.token })
//
//    // Get cart to get url to send customer back to
//    let cart = await <any>this.db.httpPost('findone', { system: this.db.system, table: 'carts', condition: { id: bizumlog1.idCart }, token: this.db.token })
//    if (!cart) return
//
//    // Show payment success or fail message
//    if (bizumlog2) {
//      this.showMessage = 1
//    } else {
//      this.showMessage = 2
//    }

    let paycometRequest = await <any>this.db.httpPost('findone', { system: this.db.system, table: 'paycometlog', condition: { id: this.reference }, token: this.db.token })
    //console.log('paycometRequest', paycometRequest)
    if (paycometRequest) {

      let paycometCallback = await <any>this.db.httpPost('findone', { system: this.db.system, table: 'paycometlog', condition: { requestId: this.reference, "body.TransactionType": '1' }, token: this.db.token })
      //console.log('paycometCallback', paycometCallback)

      let cart = await <any>this.db.httpPost('findone', { system: this.db.system, table: 'carts', condition: { id: paycometRequest.idCart }, token: this.db.token })
      //console.log('cart', cart)
      if (!cart) return

      if (paycometCallback && paycometCallback.body.Response == 'OK') {
        this.showMessage = 1
      } else {
        this.showMessage = 2
      }

      // Redirect customer to the page were he started
      let redirectPage = this.configuration.returnUrl || cart.originalUrl
      setTimeout(() => {
        window.location.assign(redirectPage)
      }, 10000)

    } else {

      // Stripe
      let order = await <any>this.db.httpPost('findone', { system: this.db.system, table: 'orders', condition: { idCart: this.reference }, token: this.db.token })
      if (order) {
        this.showMessage = 1
      } else {
        this.showMessage = 2
      }

    }
    
}

}
