import { Component, OnInit } from '@angular/core';
import { DbService, LangService } from '../../services'
import { OrderType } from '../../interfaces'
import moment from 'moment';

@Component({
  selector: 'app-step10',
  templateUrl: './step10.component.html',
  styleUrls: ['./step10.component.scss']
})
export class Step10Component implements OnInit {

  visible = false
  hideAllBut = ''
  pipeHelper = 1

  constructor(public db: DbService, public lang: LangService) {

    //console.log('Step 10', this.db.configuration.idBackground)

    if (this.db.configuration.idBackground) this.db.setBg(this.db.configuration.idBackground)

    if (this.db.link) {
      let orderType = this.db.orderTypes.find(ot => ot.link && ot.link == this.db.link)
      //if (orderType) setTimeout(() => { this.selectOrderType(orderType) }, 250)
      if (orderType) {
        this.hideAllBut = orderType.id
        this.pipeHelper += 1
        this.visible = true
      }
      
    } else {
      this.visible = true
    }

    if (this.db.system == 'pappasparlor') {
      this.db.orderType = this.db.orderTypes[0]
      this.db.navigate('/products')
    }

  }

  ngOnInit(): void {
  }

  selectOrderType(orderType: OrderType) {
    
    //console.log(orderType)
    
    //if (this.db.system == 'stangs-mjardevi') {
    //  let startMoment = moment().startOf('week').hour(8)
    //  let endMoment = moment().startOf('week').day(4).hour(23)
    //  if (!moment().isBetween(startMoment, endMoment) && (orderType.id == 'fdebb57d-1259-465b-8fb4-0e1c8730d7b9' || orderType.id == '9ccdc279-64d5-463a-ac92-c058c6ed42f7')) return
    //}

    if (this.db.system == 'smaksak') {
      let startMoment = moment().startOf('week').hour(8)
      let endMoment = moment().startOf('week').day(4).hour(12)
      if (!moment().isBetween(startMoment, endMoment) && (orderType.id == 'f52c663b-cd52-4c85-bf03-8da973b4edfe')) return
    }

    this.db.orderType = orderType
    this.db.disableExcludedProducts()
    this.db.disableExcludedAddons()

    this.db.setBg(this.db.orderType.idBackground)

    // Enable categories for this order type
    this.db.categories.filter(c => !c.idParent).forEach(c => { c.active = false })
    this.db.orderType.categories.filter(otc => otc.active).forEach(otc => {
      let c = this.db.categories.find(c => c.id == otc.idCategory)
      if (c) c.active = true
    })

    //this.db.step = 20
    if (orderType.type < 2 && orderType.idCalendar) {
      this.db.navigate('/dayandtime')
    } else {
      this.db.navigate('/products')
    }

  }

}
