<app-sticky *ngIf="db.showSticky"></app-sticky>

<div class="spacer" [style.margin-top]="db.topMargin"></div>

<!-- Client Messages -->
<div *ngFor="let cm of db.clientMessages" class="cm">{{cm.message}}</div>

<div *ngIf="db.showInfoBox" class="info" [style.font-family]="db.fonts[1]" [style]="1024 | customStyle : db.stylePipeHelper">
    <div [innerHTML]="db.project?.description1 || (db.orderType | lang : 'description' : lang.ph) | sanitizeHtml"></div>
</div>

<!-- Cateories -->
<app-categories></app-categories>

<!-- Products -->
<ng-container *ngVar="db.products | prodpipe : db.selCatProdIds : db.categoryPipeHelper as catProds">
    <ng-container *ngVar="catProds.length as cpl">

        <div *ngIf="db.products" class="outer" [class.outer1]="cpl == 1" [class.outer2]="cpl == 2" [class.outer3]="cpl == 3" [style.gridTemplateColumns]="db.compact ? '50% 50%' : ''">

            <ng-container *ngFor="let product of catProds">

                <div *ngIf="product.id == 'bac1cdc8-257c-42fd-a8ae-cf8a796d8bc4'" class="item specialitem">
                    <div><img [src]="'/assets/biokoltext.gif'" class="specialimg"></div>
                </div>
                
                <div  class="item" [style.backgroundColor]="db.configuration.boxColor" [style.color]="db.configuration.boxTextColor" [style]="1023 | customStyle : db.stylePipeHelper">
                    <app-product-item [product]="product"></app-product-item>
                </div>

            </ng-container>

        </div>
    </ng-container>
</ng-container>

<!--
<div style="background-color: white; padding: 10px; border: 1px solid white; border-radius: 10px;">
    <pre>{{db.cartProducts|json}}</pre>
</div>
-->