<div class="outer">

    <div>
        <img [src]="'https://db20.bokad.se/images/' + db.system + '/c9517324-708c-40b6-83e8-53f440f3a5a2'" class="logo" [style]="1028 | customStyle : db.stylePipeHelper"/>
    </div>

    <div *ngIf="step == 1">

        <div style="text-align: center">

            <div class="c1">Order {{order.orderNumber}}</div>

            <table class="t0">
                <tr>
                    <td class="td1">Name</td>
                    <td class="td2">{{order.firstName}} {{order.lastName}}</td>
                </tr>
                <tr>
                    <td class="td1">E-mail</td>
                    <td class="td2">{{order.email}}</td>
                </tr>
            </table>

            <div class="proddiv">
                <div style="font-weight: 700; margin-bottom: 6px;">Products:</div>
                <table>
                    <tr *ngFor="let op of order.products">
                        <td>{{op.quantity}}</td>
                        <td>{{op.name}}</td>
                    </tr>
                </table>
            </div>

            <div class="proddiv">

                <div style="font-weight: 700; margin-bottom: 6px;">Address:</div>

                <table class="t0">

                    <tr>
                        <td class="td1">Phone</td>
                        <td class="td3"><input style="width: 100%; font-size: 16px;" matInput name="phone" [(ngModel)]="order['phone']" maxlength="40"></td>
                    </tr>

                    <tr>
                        <td class="td1">Address 1</td>
                        <td class="td3"><input style="width: 100%; font-size: 16px;" matInput name="address1" [(ngModel)]="order['address1']" maxlength="40"></td>
                    </tr>
  
                    <tr>
                        <td class="td1">Address 2</td>
                        <td class="td3"><input style="width: 100%; font-size: 16px;" matInput name="address2" [(ngModel)]="order['address2']" maxlength="40"></td>
                    </tr>
  
                    <tr>
                        <td class="td1">Postcode</td>
                        <td class="td3"><input style="width: 100%; font-size: 16px;" matInput name="postCode" [(ngModel)]="order['postCode']" maxlength="40"></td>
                    </tr>
  
                    <tr>
                        <td class="td1">City</td>
                        <td class="td3"><input style="width: 100%; font-size: 16px;" matInput name="city" [(ngModel)]="order.city" maxlength="40"></td>
                    </tr>
  
                    <tr>
                        <td class="td1">Country</td>
                        <td class="td3"><input style="width: 100%; font-size: 16px;" matInput name="country" [(ngModel)]="order['country']" maxlength="40"></td>
                    </tr>
                </table>
            </div>

            <div class="btndiv">
                <button mat-raised-button color="primary" (click)="saveOrder()">SAVE</button>

            </div>
        </div>

    </div>

    <div *ngIf="step == 2">
        <div class="step2">Order information is updated,<br>thank you!</div>
    </div>

</div>