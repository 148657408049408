<div class="outer noselect">

    <div *ngIf="db.configuration.idLogotype" class="logodiv">
        <!--<img class="logo" [src]="'https://db20.bokad.se/images/' + db.system + '/' + db.configuration.idLogotype"/>-->
        <img class="logo" [src]="'https://media.floworder.se/' + db.system + '/images/' + db.configuration.idLogotype"/>
    </div>

    <div *ngIf="step == 1" class="content">

        <div class="ordernr">Order {{order.orderNumber}}</div>

        <div class="tablediv">
            <table>
                <tr *ngFor="let op of order.products">
                    <td style="text-align: right; padding-right: 8px;">{{op.quantity}}</td>
                    <td style="text-align: left;">{{op.name}}</td>
                </tr>
            </table>
        </div>

        <div class="btn" (click)="kvittera()" [class.btninvert]="order.delivered">{{btnText}}</div>
        <div class="btninfo">(Kvitteras av personal vid utlämning)</div>
        
    </div>

    <div *ngIf="step == 2" class="content">
        <div class="missing">Order saknas eller redan kvitterad.</div>
    </div>

</div>