import { Component, OnInit } from '@angular/core';
import { DbService, LangService } from '../../services'
import { faShoppingCart } from '@fortawesome/pro-light-svg-icons'
import { MatSnackBar } from '@angular/material/snack-bar'

@Component({
  selector: 'app-sticky',
  templateUrl: './sticky.component.html',
  styleUrls: ['./sticky.component.scss']
})
export class StickyComponent implements OnInit {

  faShoppingCart = faShoppingCart;

  constructor(public db: DbService, public lang: LangService, public snackBar: MatSnackBar) { }

  ngOnInit(): void {
    //console.log('Sticky init')
  }

  ngOnDestroy() {
    //console.log('Sticky destroy')
  }

  cartClick() {

    // Return if no products
    if (!this.db.cart.products.length) return this.snackBar.open(this.lang.l(213), '', { duration: 2000, panelClass: 'green-snackbar' })
    
    // Return if to few dishes
    //if (this.db.orderType.type < 2 && this.db.orderType.minimumDishes && this.db.dishCount() < this.db.orderType.minimumDishes) return this.snackBar.open(`${this.lang.l(286)} ${this.db.orderType.minimumDishes}`, '', { duration: 2000, panelClass: 'green-snackbar' })
    if (this.db.orderType.minimumDishes && this.db.dishCount() < this.db.orderType.minimumDishes) return this.snackBar.open(`${this.lang.l(286)} ${this.db.orderType.minimumDishes}`, '', { duration: 2000, panelClass: 'green-snackbar' })
    
    // Return if to low order amount
    this.db.calculateCartTotals() // Just to make sure
    if (this.db.orderType.type < 2 && this.db.orderType.minimumAmount && this.db.cart.totalAmount < this.db.orderType.minimumAmount) return this.snackBar.open(`${this.lang.l(287)} ${this.db.orderType.minimumAmount} ${this.db.configuration.currency}`, '', { duration: 2000, panelClass: 'green-snackbar' })

    //this.db.step = 50
    this.db.navigate('/cart')

  }

}
