<div *ngIf="visible" class="outer">

    <div *ngIf="db.configuration.idLogotype" class="logodiv">
        <!--<img class="logo" [src]="'https://db20.bokad.se/images/' + db.system + '/' + db.configuration.idLogotype"/>-->
        <img class="logo" [src]="'https://media.floworder.se/' + db.system + '/images/' + db.configuration.idLogotype"/>
    </div>

    <div *ngFor="let ot of db.orderTypes | selectOrderTypes : hideAllBut : pipeHelper" class="otdiv" (click)="selectOrderType(ot)">
        <div class="otn" [style.font-family]="db.fonts[0]">{{ (ot | lang : 'displayName' : lang.ph) || (ot | lang : 'name' : lang.ph) }}</div>
        <div *ngIf="ot.subtext" class="ots" [innerHTML]="(ot | lang : 'subtext' : lang.ph) | sanitizeHtml"></div>
    </div>
    
</div>