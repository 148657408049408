import { Pipe, PipeTransform } from '@angular/core';
import { Product } from '../interfaces'
import moment from 'moment'
import { DbService } from '../services'

@Pipe({
  name: 'prodpipe'
})
export class ProdpipePipe implements PipeTransform {

  constructor(private db: DbService) {}

  transform(products: Product[], selCatProdIds: any[], pipeHelper: number): Product[] {

    let r = []

    selCatProdIds.forEach(spid => {
      let p = products.find(p => p.id == spid)

      let allow = p && p.active

      if (p && p.blockedTo) {

        let deliveryDate = `${this.db.cart.deliveryDate} ${this.db.cart.deliveryTime}`
        if (!deliveryDate.trim()) deliveryDate = moment().format('YYYY-MM-DD HH:mm:ss')

        if (moment(p.blockedTo).isAfter(moment(deliveryDate))) allow = false

      }

      if (allow) r.push(p)

      //if (p && p.active && !p.blockedTo) r.push(p)
    })

    return r

  }

}
