import { Pipe, PipeTransform } from '@angular/core';
import { DbService } from '../services'

@Pipe({
  name: 'cartCounter'
})
export class CartCounterPipe implements PipeTransform {

  constructor(private db: DbService) {}

  transform(pipeHelper: number): string {
    return this.db.cart.products.filter(cp => !cp.idParent).reduce((acc, cp) => acc += cp.quantity, 0).toString()
  }

}
